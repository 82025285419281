const base_url = process.env.REACT_APP_BASE_URL;

// Vocabulary practice level API
export const vocabularyPracticeLevelApi = async (
  programId,
  levelId,
  authToken
) => {
  const response = await fetch(
    `${base_url}/sd/v1/vocabularyPractice/getAllLevels?programId=${programId}&levelId=${levelId}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );

  const responseJson = await response.json();
  return responseJson;
};

// Vocabulary Practice API
export const VocabularyPracticeStartApi = async (
  programId,
  levelId,
  levelOrder,
  authToken
) => {
  const payload = {
   programId: programId,
    levelId: levelId,
    levelOrder: levelOrder,
  };

  const response = await fetch(`${base_url}/sd/v1/vocabularyPractice/start`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });

  const responseJson = await response.json();
  return responseJson;
};

// Vocabulary Skip API
export const VocabularyPracticeSkipApi = async (
 programId,
  levelId,
  questionId,
  levelOrder,
  timeSpent,
  authToken
) => {
  const payload = {
    programId: programId,
    levelId: levelId,
    questionId: questionId,
    levelOrder: levelOrder,
    timeSpent: timeSpent,
  };

  const response = await fetch(`${base_url}/sd/v1/vocabularyPractice/skip`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });

  const responseJson = await response.json();
  return responseJson;
};

// Vocabulary Submit API
export const VocabularyPracticeSubmitApi = async (
  programId,
  levelId,
  levelOrder,
  questionId,
  answer,
  timeSpent,
  authToken
) => {
  const payload = {
    programId: programId,
    levelId: levelId,
    levelOrder: levelOrder,
    questionId: questionId,
    submittedAnswer: [answer],
    timeSpent: timeSpent,
  };

  const response = await fetch(`${base_url}/sd/v1/vocabularyPractice/submit`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });

  const responseJson = await response.json();
  return responseJson;
};
