const BASE_URL = process.env.REACT_APP_BASE_URL;

/**
 * Creates fetch options for API calls.
 * @param {string} token - The authorization token for the API.
 * @param {string} studentEmail - The email of the student.
 * @param {string} method - The HTTP method for the request (default is 'GET').
 * @returns {Object} - An object containing the fetch options.
 */
const createFetchOptions = (token, studentEmail, method = 'GET') => ({
  method,
  headers: {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json',
  },
  body: method === 'POST' ? JSON.stringify({ studentEmail }) : undefined,
});

/**
 * Formats the provided start and end dates into UNIX timestamps.
 * @param {Date|string} startDate - The start date to format.
 * @param {Date|string} endDate - The end date to format.
 * @returns {Object} - An object containing formatted start and end dates in seconds.
 */
const formatDates = (startDate, endDate) => {
  if (startDate && endDate) {
    const startDateTime = new Date(startDate).setHours(0, 0, 0, 0);
    const endDateTime = new Date(endDate).setHours(23, 59, 59, 999);
    return {
      startDate: Math.floor(startDateTime / 1000),
      endDate: Math.floor(endDateTime / 1000),
    };
  }
  return {};
};

/**
 * Fetches the student's progress stats report based on provided parameters.
 * @param {string} token - The authorization token for the API.
 * @param {string} programId - The ID of the program for which to fetch stats.
 * @param {Date|string|null} startDate - The start date for filtering results.
 * @param {Date|string|null} endDate - The end date for filtering results.
 * @param {string} studentEmail - The email of the student for whom to fetch stats.
 * @returns {Promise<Object>} - A promise that resolves to the JSON response from the API.
 * @throws {Error} - Throws an error if the BASE_URL is not defined or if the fetch fails.
 */
export const getStudentProgressStatsReport = async (token, programId, startDate = null, endDate = null, studentEmail) => {
  if (!BASE_URL) {
    throw new Error('BASE_URL is not defined in environment variables.');
  }

  try {
    let url;
    let options;
    const dateParams = formatDates(startDate, endDate);
    const params = new URLSearchParams({ programId, ...dateParams });

    if (window.location.href.includes("spelling")) {
      url = new URL(`${BASE_URL}/sd/v1/spellingPractice/progress`);
      options = createFetchOptions(token); 
    } else if (window.location.href.includes("vocabulary")) {
      url = new URL(`${BASE_URL}/sd/v1/vocabularyPractice/progress`);
      options = createFetchOptions(token);
    } else if (window.location.href.includes("reading")) {
      url = new URL(`${BASE_URL}/sd/v1/getReadingProgressStats`);
      options = createFetchOptions(token);
    } else if (window.location.href.includes("writing")) {
      url = new URL(`${BASE_URL}/sd/v1/getStudentProgressStatsReportWriting`);
      options = createFetchOptions(token);
    } else {
      url = new URL(`${BASE_URL}/sd/v1/getStudentProgressStatsReport`);
      options = createFetchOptions(token, studentEmail);
    }

    url.search = params.toString();
    
    const response = await fetch(url, options);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching student progress stats:", error);
    throw error;
  }
};

/**
 * Fetches detailed mastery report for a specific level based on mastery type.
 * @param {string} levelId - The ID of the level for which to fetch the mastery report.
 * @param {string} programId - The ID of the program for which to fetch the mastery report.
 * @param {string} token - The authorization token for the API.
 * @param {string} masteryType - The type of mastery to fetch ('initial' or 'current').
 * @returns {Promise<Object|null>} - A promise that resolves to the mastery report data or null if an error occurs.
 */
export const fetchMasteryByLevelId = async (levelId, programId, token, masteryType) => {
  try {
    const endpoint = masteryType === 'initial' ? '/sd/v1/initialMasteryByLevel' : '/sd/v1/currentMasteryByLevel';
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ levelId, programId }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error);
    return null;
  }
};

/**
 * Fetches details for completed spelling or vocabulary practice.
 * @param {string} spellingPracticeId - The ID of the spelling practice to fetch details for.
 * @param {string} vocabularyPracticeId - The ID of the vocabulary practice to fetch details for.
 * @param {string} token - The authorization token for the API.
 * @returns {Promise<Object>} - A promise that resolves to the details of the completed practice.
 * @throws {Error} - Throws an error if the fetch operation fails.
 */
export const fetchSpellingAndVocabularyDetails = async (spellingPracticeId, vocabularyPracticeId, token) => {
  try {
    let endpoint;
    
    if (window.location.href.includes("vocabulary")) {
      endpoint = `${BASE_URL}/sd/v1/vocabularyPractice/completedDetails/${vocabularyPracticeId}`;
    } else {
      endpoint = `${BASE_URL}/sd/v1/spellingPractice/completedDetails/${spellingPracticeId}`;
    }
    const response = await fetch(endpoint, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch details: ' + response.statusText);
    }

    const data = await response.json();
    if (!data.success) {
      throw new Error('Failed to retrieve details: ' + data.message);
    }

    return data.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};