import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import LibraryQuestionComponent from "./LibraryQuestionComponent";
import { parseLatexText } from "../../Utils/LatexUtils";
import ExplanationModalContent from "../../Pages/Test/TestBuilder/ExplanationModalContent.jsx";
import { libraryNextQuestionThunk } from "../../redux/slices/library/librarySlice.js";
import questionTypeConfig from "../../Pages/Test/questionTypeConfig.js";
import Timer from "./LibraryTimer.jsx";
import LibraryFinishCard from "./LibraryFinishCard.jsx";
import { useLocation } from "react-router-dom";

const LibraryTestComponent = ({ handleBackTestClick,
  timeSpent,
  setTimeSpent }) => {

  const { questionData: data, testError: libraryError } = useSelector((state) => state.library)
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { sdaccessToken } = useSelector((state) => state.details);
  const dispatch = useDispatch();
  const [speakingIndex, setSpeakingIndex] = useState("");
  const speechSynthesisInstance = window.speechSynthesis;
  const [textExplanationData, setTextExplanationData] = useState(
    data?.textExplanation
  );
  const [isCompleted, setIsCompleted] = useState(
    data?.isCompleted || false
  );
  const [selectedOption, setSelectedOption] = useState([]);

  const [isRunning, setIsRunning] = useState(true);

  const handleExplanationModalOpenClose = () => {
    let payload = JSON.parse(localStorage.getItem('libraryCurrentQuestion') || '{}');
    payload.textExplanation = {};
    localStorage.setItem('libraryCurrentQuestion', JSON.stringify(payload));
    setIsModalOpen(!isModalOpen);
  };

  const handleSpeak = (text, index) => {
    if ("speechSynthesis" in window) {
      const replacedText = text
        .replace(/<#@blank#>/g, "blank")
        .replace(/_{2,}/g, "blank");

      const utterance = new SpeechSynthesisUtterance(
        parseLatexText(replacedText)
      );
      if (speechSynthesisInstance.speaking || speechSynthesisInstance.pending) {
        if (speakingIndex === index) {
          speechSynthesisInstance.cancel();
          setSpeakingIndex(null);
          return;
        } else {
          speechSynthesisInstance.cancel();
        }
      }
      setSpeakingIndex(index);
      speechSynthesisInstance.speak(utterance);
      utterance.onend = () => setSpeakingIndex(null);
    }
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        if (
          speechSynthesisInstance.speaking ||
          speechSynthesisInstance.pending
        ) {
          speechSynthesisInstance.cancel();
          setSpeakingIndex(null);
        }
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const handleOptionSelection = (option) => {
    if (questionTypeConfig.isMultiSelect.includes(data.currentQuestion.qtype)) {
      setSelectedOption((prev) => {
        if (prev.includes(option)) {
          return prev.filter((item) => item !== option);
        } else {
          return [...prev, option];
        }
      });
    } else {
      setSelectedOption([option]);
    }
  };

  const handleTimeUpdate = (updatedTime) => {
    setTimeSpent(updatedTime);
  };



  const handleAnsweringBlanks = (text) => {
    setSelectedOption([text]);

  };

  const handleSubmitAnswer = () => {
    let submittedData = {};
    if (location.pathname.includes('reading')) {
      submittedData = {
        "assignmentId": data.assignmentId,
        "questionId": data.currentQuestion?.questionId,
        "parentQuestionId": data.currentQuestion?.passageId,
        "submittedAnswer": selectedOption.flat(),
        "isSkipped": 0,
        "timeSpent": timeSpent - data?.totalTimeSpent
      }
    } else {
      submittedData = {
        "assignmentId": data.assignmentId,
        "questionId": data.currentQuestion?.questionId,
        "submittedAnswer": selectedOption.flat(),
        "isSkipped": 0,
        "timeSpent": timeSpent - data?.totalTimeSpent
      }
    }
    dispatch(libraryNextQuestionThunk({ token: sdaccessToken, data: submittedData }))
  }

  if (libraryError) {
    return (
      <>
        <i
          className="bi bi-arrow-left custom-icon mb-1 pe-0"
          onClick={handleBackTestClick}
        >
        </i>
        <div className="auth-err-msg">
          Error processing student library assignment.
        </div>
      </>
    );
  }

  if (data.message) {
    return (
      <>
        <i
          className="bi bi-arrow-left custom-icon mb-1 pe-0"
          onClick={handleBackTestClick}
        >
        </i>
        <div className="auth-err-msg">{data.message}</div>
      </>
    )
  }

  return (
    <div>
      {data && Object.keys(data).length > 0 &&
        <div className="d-flex flex-row justify-content-between library-question-parent-container"
          style={{ backgroundColor: isCompleted ? "rgba(0,0,0,1" : "" }}>
          {textExplanationData && Object.keys(textExplanationData).length > 0 ? (
            <div
              className="modal-backdrop d-flex justify-content-center align-items-center 
                rounded-2 p-5 position-relative w-100 h-100 top-0 start-0"
              onClick={(e) => e.stopPropagation()}
              style={{ zIndex: 1050 }}
            >
              <div className="custom-modal-container">
                <ExplanationModalContent
                  handleExplanationModalClose={handleExplanationModalOpenClose}
                  handleSpeak={handleSpeak}
                  textExplanation={textExplanationData}
                  setTextExplanationData={setTextExplanationData}
                  speakingIndex={speakingIndex}
                  isCompleted={isCompleted}
                />
              </div>
            </div>
          ) : isCompleted ?
            <LibraryFinishCard handleContinue={handleBackTestClick} />
            : (
              <div className="d-flex flex-row justify-content-between w-100">
                <div className="w-75 overflow-auto p-3">
                  <div className="d-flex align-items-center gap-1">
                    <i
                      className="bi bi-arrow-left custom-icon mb-1 pe-0"
                      onClick={handleBackTestClick}
                    ></i>
                    <div className="fw-bold">
                      Question no: {data?.attemptedQuestionsCount + 1}
                    </div>
                  </div>
                  <LibraryQuestionComponent
                    data={data}
                    handleSpeak={handleSpeak}
                    speakingIndex={speakingIndex}
                    handleOptionSelection={handleOptionSelection}
                    selectedOption={selectedOption}
                    handleAnsweringBlanks={handleAnsweringBlanks}
                    handleSubmitAnswer={handleSubmitAnswer}
                  />
                </div>
                <div className="library-sidebar">
                  <div className="d-flex flex-column align-items-center 
                      justify-content-around h-100 text-center" style={{ maxHeight: "75vh" }}>
                    <div>
                      <div className="d-flex justify-content-center fs-3 fw-bold">
                        {data?.attemptedQuestionsCount}
                      </div>
                      <div className="fw-medium">Questions Answered</div>
                    </div>
                    <div>
                      <div className="d-flex justify-content-center fs-3 fw-bold">

                        <Timer
                          startFrom={data?.totalTimeSpent || 0}
                          isRunning={isRunning}
                          onTimeUpdate={handleTimeUpdate}
                        />
                      </div>
                      <div className="fw-medium">Time spent</div>

                    </div>
                    <div>
                      <div className="d-flex justify-content-center fs-3 fw-bold">
                        {data.accuracyRate}%
                      </div>
                      <div className="fw-medium">Accuracy rate</div>
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>
      }

    </div>
  );
};

export default LibraryTestComponent;