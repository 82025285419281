import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import LibraryTopics from "./LibraryTopics";
import LibraryDomains from "./LibraryDomains";
import ProgramLevels from "./ProgramLevels";
import { useAuthenticate } from "../../hooks/useAuthenticate";
import Loader from "../Loader/Loader";
import "./Library.css";
import LibraryTestComponent from "./LibraryTestComponent";
import { useDispatch } from "react-redux";
import { getAllLevelsByProgramId } from "../../Utils/ApiHandler/AssignTestApi";
import {
  getDomainsByLevelIdThunk,
  getTopicsByDomainIdThunk,
  getSkillsByTopicIdThunk,
  clearLibraryDomains,
  startLibraryQuestionThunk,
  clearLibraryQuestion,
  libraryQuestionTimePause
} from "../../redux/slices/library/librarySlice";
import { handleSkillSelection } from "./LibraryUtils";

const LibraryHome = () => {
  const { isAuthenticated, authLoading } = useAuthenticate();
  const authToken = useSelector((state) => state.details.sdaccessToken);
  const studentGrade = useSelector((state) => state.details.levelOrder);
  const { questionData, error: libraryError } = useSelector((state) => state.library)
  const dispatch = useDispatch();
  const { firstName, lastName, workEmail } = useSelector(
    (state) => state.details
  );

  let programName;
  const location = useLocation();
  if (location?.pathname?.includes("math")) {
    programName = "Math";
  } else if (location?.pathname?.includes("ela/language")) {
    programName = "ELA";
  } else if (location?.pathname?.includes("ela/reading")) {
    programName = "Reading";
  } else {
    programName = "";
  }

  const programs = useSelector((state) => state?.details?.enrolledPrograms);

  const programId = programs.find((p) => p.title === programName)?._id;

  const {
    data: libraryData,
    loading: libraryLoading,
  } = useSelector((state) => state.library);

  const [showTopics, setShowTopics] = useState(localStorage.getItem('showTopics'));
  const topics = libraryData?.topics;
  const domains = libraryData?.domains;
  const [loadingLevels, setLoadingLevels] = useState(true);
  const [programLevels, setProgramLevels] = useState([]);
  const [timeSpent, setTimeSpent] = useState(questionData?.totalTimeSpent || 0);
  const [selectedLevel, setSelectedLevel] = useState(programLevels?.[studentGrade - 1]?._id);
  const [selectedDomain, setSelectedDomain] = useState(localStorage.getItem('selectedDomain') || null);
  const [currentDomain, setCurrentDomain] = useState(
    JSON.parse(localStorage.getItem('currentDomain')) || null);
  const [selectedSkill, setSelectedSkill] = useState(localStorage.getItem('selectedSkill'));
  const [selectedTopic, setSelectedTopic] = useState(localStorage.getItem('selectedTopic') || null);
  const [questionApiPayload, setQuestionApiPayload] = useState(
    JSON.parse(localStorage.getItem('questionPayload')) || {});
  useEffect(() => {
    setSelectedLevel(localStorage.getItem('selectedLevel') || programLevels?.[studentGrade - 1]?._id);
  }, [programLevels]);
  useEffect(() => {
    if (selectedLevel && authToken && questionApiPayload && Object.keys(questionApiPayload).length === 0) {
      dispatch(
        getDomainsByLevelIdThunk({ token: authToken, levelId: selectedLevel })
      );
    }
  }, [selectedLevel, authToken, dispatch]);

  useEffect(() => {
    if (selectedDomain && authToken && Object.keys(questionApiPayload).length === 0) {
      dispatch(
        getTopicsByDomainIdThunk({ token: authToken, domainId: selectedDomain })
      );
    }
  }, [selectedDomain, authToken, dispatch]);

  const topic = useSelector((state) =>
    state.library.data?.topics?.find((t) => t._id === selectedTopic)
  );

  useEffect(() => {
    if (selectedTopic && authToken && questionApiPayload && Object.keys(questionApiPayload).length === 0) {

      if (topic && (!topic.skills || topic.skills.length === 0)) {
        dispatch(
          getSkillsByTopicIdThunk({ token: authToken, topicId: selectedTopic })
        );
      }
    }
  }, [selectedTopic, authToken, dispatch]);

  const handleDomainChange = (domainId) => {
    setSelectedDomain(domainId);
    localStorage.setItem('selectedDomain', domainId);
    setShowTopics(true);
    localStorage.setItem('showTopics', true);
    localStorage.setItem('libraryDomains',
      JSON.stringify(domains))
    setCurrentDomain(domains.find((domain) => domain._id === domainId));
    localStorage.setItem('currentDomain',
      JSON.stringify(domains.find((domain) => domain._id === domainId)));
  };

  const handleTopicBack = () => {
    setSelectedDomain(null);
    setShowTopics(false);
    setCurrentDomain(null);
    setSelectedTopic(null);
    localStorage.removeItem('libraryTopics')
    localStorage.removeItem('currentDomain');
    localStorage.removeItem('showTopics');
    localStorage.removeItem('selectedTopic');
    dispatch(
      getDomainsByLevelIdThunk({ token: authToken, levelId: selectedLevel })
    );
  };

  const handleTimePause = (data) => {
    const pauseData = {
      "assignmentId": data?.assignmentId,
      "questionId": data.currentQuestion?.questionId,
      "timeSpent": localStorage.getItem("libraryTimer") - data?.totalTimeSpent,
      "isPause": true
    };
    localStorage.removeItem('libraryCurrentQuestion')
    if(pauseData?.timeSpent) {
      dispatch(libraryQuestionTimePause({ token: authToken, data: pauseData }))
    } 
  }

  useEffect(() => {
    console.log(timeSpent, questionData?.totalTimeSpent)
  },[timeSpent])
  useEffect(() => {
    const payload = JSON.parse(localStorage.getItem('libraryCurrentQuestion'));
    if (questionApiPayload && Object.keys(questionApiPayload).length !== 0 && authToken && !payload) {
      dispatch(startLibraryQuestionThunk({ token: authToken, data: questionApiPayload }))
    }
  }, [questionApiPayload, authToken, dispatch]);

  const handleSkillSelect = (skillId) => {
    setSelectedSkill(skillId);
    localStorage.setItem('selectedSkill', skillId);
    const data = handleSkillSelection(
      skillId,
      topics,
      selectedLevel,
      currentDomain,
      programId,
      firstName,
      lastName,
      workEmail,
      domains
    );
    setQuestionApiPayload(data);
    localStorage.setItem('questionPayload', JSON.stringify(data));
  };

  const handleTopicSelect = (topicId) => {
    setSelectedTopic(topicId);
    localStorage.setItem('selectedTopic', topicId);
  };

  const handleLevelSelect = (levelId) => {
    setShowTopics(false);
    setSelectedLevel(levelId);
    const previousLevel = localStorage.getItem("selectedLevel");
    if(previousLevel === levelId) {
      dispatch(getDomainsByLevelIdThunk({ token: authToken, levelId: selectedLevel }))
    }
    if(questionData && Object.keys(questionData).length>0 && !questionData?.isCompleted){
      handleTimePause(questionData);
    } else {
      localStorage.removeItem('libraryCurrentQuestion')
    }
    localStorage.setItem("selectedLevel", levelId);
    localStorage.removeItem('libraryTopics');
    localStorage.removeItem('selectedTopic');
    localStorage.removeItem('currentDomain');
    localStorage.removeItem('showTopics');
    localStorage.removeItem('selectedSkill');
    localStorage.removeItem('questionPayload');
    localStorage.removeItem('libraryDomains');
    localStorage.removeItem('libraryTimer');
    setQuestionApiPayload({});
    localStorage.removeItem('libraryTopics')
    dispatch(clearLibraryQuestion());
    localStorage.removeItem('selectedTopic')
    setSelectedDomain(null);
    setSelectedSkill(null);
    setSelectedTopic(null);
  };

  useEffect(() => {
    return () => {
      const payload = JSON.parse(localStorage.getItem('libraryCurrentQuestion'))
      if(payload && Object.keys(payload).length>0 && !payload?.isCompleted){
        handleTimePause(payload);
      } else {
        localStorage.removeItem('libraryCurrentQuestion')
      }
      localStorage.removeItem("selectedLevel");
      localStorage.removeItem('libraryTopics')
      localStorage.removeItem('currentDomain');
      localStorage.removeItem('libraryDomains');
      localStorage.removeItem('showTopics');
      localStorage.removeItem('selectedSkill');
      localStorage.removeItem('questionPayload');
      localStorage.removeItem('selectedDomain');
      localStorage.removeItem('selectedTopic');
      localStorage.removeItem('libraryTimer');
      dispatch(clearLibraryDomains());
       dispatch(clearLibraryQuestion());
    }
  }, [])

  const handleBackTestClick = () => {
    setSelectedSkill(null);
    if(questionData && Object.keys(questionData).length>0 && !questionData?.isCompleted){
      handleTimePause(questionData);
    } else {
      localStorage.removeItem('libraryCurrentQuestion')
    }
    localStorage.removeItem('selectedSkill');
    localStorage.removeItem('questionPayload');
    localStorage.removeItem('selectedTopic');
    localStorage.removeItem('libraryTopics');
    dispatch(clearLibraryQuestion());
    localStorage.removeItem('libraryTimer');
    setSelectedTopic(null);
    setQuestionApiPayload({});
    dispatch(getTopicsByDomainIdThunk({ token: authToken, domainId: selectedDomain }))
  }

  useEffect(() => {
    const getLevelsData = async () => {
      try {
        const levels = await getAllLevelsByProgramId(programId, authToken);
        if (levels?.data) {
          setLoadingLevels(false);
          setProgramLevels(levels?.data?.levels);
        }
      } catch (e) {
        console.error("Error fetching data", e);
      }
    };
    if (authToken) {
      getLevelsData(programId, authToken);
    }
  }, [programId, authToken]);
  if (authLoading || loadingLevels) {
    return <Loader />;
  }
  if (!isAuthenticated) {
    return <div className="auth-err-msg">You are not authenticated.</div>;
  }

  // if (libraryError) {
  //   return (
  //     <div className="auth-err-msg">
  //       Error encountered while fetching library data. Please try again later...
  //     </div>
  //   );
  // }
if(!libraryError){
  return (
    <Container fluid>

      <Row className="justify-content-md-center p-3">
        <Col xs={3} md={2} lg={2} className="p-1">
          {programLevels && (
            <ProgramLevels
              programLevels={programLevels}
              selectedLevel={selectedLevel}
              handleLevelSelect={handleLevelSelect}
            />
          )}
        </Col>
        <Col xs={9} md={10} lg={10} className="p-1">
          {libraryLoading ? (
            <Loader />
          ) : selectedSkill && questionApiPayload
            ? (
              <LibraryTestComponent
                handleBackTestClick={handleBackTestClick}
                timeSpent={timeSpent}
                setTimeSpent={setTimeSpent}
              />
            ) : showTopics ? (
              <LibraryTopics
                topics={topics}
                domain={currentDomain}
                handleTopicSelect={handleTopicSelect}
                handleSkillSelect={handleSkillSelect}
                handleTopicBack={handleTopicBack}
              />
            ) : (
              <LibraryDomains
                domains={domains}
                handleDomainChange={handleDomainChange}
              />
            )}
        </Col>
      </Row>
    </Container>
  );
}
  
};

export default LibraryHome;