import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { flagQuestionCommentApi } from "../../../Utils/ApiHandler/FlagQuestionCommentApi";

export const flagComment = createAsyncThunk(
  "flagComment/flagComment",
  async ({ questionId, flagReason, token, assignmentId, fetchTestDetails, practiceSessionId,
    cmsQuestionId, getSpellingAndVocabularyDetails }) => {
    const response = await flagQuestionCommentApi(
      questionId,
      flagReason,
      token,
      assignmentId,
      fetchTestDetails,
      practiceSessionId,
      cmsQuestionId,
      getSpellingAndVocabularyDetails
    );
    return response;
  }
);

const initialState = {
  loading: false,
  error: null,
  success: false,
};

const flagCommentSlice = createSlice({
  name: "flagComment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(flagComment.pending, (state) => {
        state.loading = true;
      })
      .addCase(flagComment.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(flagComment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default flagCommentSlice.reducer;
