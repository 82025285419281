import React from 'react';
import { useSelector } from 'react-redux';
import { ListGroup } from 'react-bootstrap';

const ProgramLevels = ({ programLevels, selectedLevel, handleLevelSelect }) => {
  const {
    error : libraryError
  } = useSelector((state) => state.library);
  
  const gradeColor = [
    "#6BB5FE", "#FF81CC", "#F79D69", "#7CC953",
    "#DE8BE6", "#A0ADBF", "#CCBD43", "#7FC2FE",
    "#7FC2FE", "#7FC2FE"
  ];
  if (libraryError){
    return <div className="auth-err-msg">Failed to fetch levels</div>
  }
  if(!programLevels){
    return <div className="auth-err-msg">Levels are not available</div>
  }
  return (
    <ListGroup className="d-flex flex-column gap-2">
      {programLevels
        .sort((a, b) => a.order - b.order)
        .map((level, index) => {
          const color = gradeColor[index % gradeColor.length];
          const isActive = selectedLevel === level._id; 

          return (
            <ListGroup.Item
              key={level._id}
              className="d-flex justify-content-between"
              onClick={() => handleLevelSelect(level._id)} // Set the clicked item as active
              style={{
                borderRadius: "4px",
                padding: "14px 10px",
                borderTop: isActive ? `2px solid ${color}` : "2px solid transparent" ,
                
                borderRight: isActive ? `2px solid ${color}` : "2px solid transparent" ,
                
                borderBottom: isActive ? `2px solid ${color}` : "2px solid transparent" ,
                borderLeft: `8px solid ${color}`, // Always 8px left border
                boxShadow: "0px 1px 8px 0px rgba(147, 147, 147, 0.12)",
                transition: "all 0.1s ease", // Smooth transition for hover effects
                cursor: "pointer",
                color: isActive ? color : "initial", // Apply text color based on selection
                maxHeight: "60vh",
                overflowY: "scroll"
              }}
              onMouseEnter={(e) => {
                if (!isActive) { // Only apply hover effects if not active
                  e.currentTarget.style.borderLeft = `10px solid ${color}`;
                  e.currentTarget.style.borderTop = `1px solid ${color}`;
                  e.currentTarget.style.borderRight = `1px solid ${color}`;
                  e.currentTarget.style.borderBottom = `1px solid ${color}`;
                  e.currentTarget.style.color = color;
                  e.currentTarget.querySelector('i').style.color = color;
                }
              }}
              onMouseLeave={(e) => {
                if (!isActive) { // Revert hover effects only if not active
                  e.currentTarget.style.borderLeft = `8px solid ${color}`;
                  e.currentTarget.style.borderTop = `1px solid transparent`;
                  e.currentTarget.style.borderRight = `1px solid transparent`;
                  e.currentTarget.style.borderBottom = `1px solid transparent`;
                  e.currentTarget.style.color = "initial";
                  e.currentTarget.querySelector('i').style.color = "";
                }
              }}
           
            >
              <span>{level.title.replace("Level", "Grade")}</span>
              <span >
                <i className="bi bi-chevron-right"  style={{color: isActive ? color : "black"}}></i>
              </span>
            </ListGroup.Item>
          );
        })}
    </ListGroup>
  );
};

export default ProgramLevels;
