import toast from "react-hot-toast";
const BASE_URL = process.env.REACT_APP_BASE_URL;

// fetching student assignments
export const getAllStudentAssignments = async (
  email,
  token,
  programId,
  isProgress,
  page
) => {
  let url;
  const currentPage = localStorage.getItem("currentPage");
  url = `${BASE_URL}/sd/v1/getAllStudentAssignments?limit=12&assignedToEmail=${email}&page=${page}&status=${
    isProgress ? 0 : 1
  }&programId=${programId}`;
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error(`Network response was not ok: ${response.statusText}`);
  }
  return response.json();
};

// deleting student assignment
export const deleteStudentAssignment = async (assignmentId, token) => {
  try {
    const response = await fetch(`${BASE_URL}/sd/v1/deleteStudentAssignment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ studentAssignmentId: assignmentId }),
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    } else {
      toast.success("Assignment deleted successfully.");
    }
    return response.json();
  } catch (error) {
    throw new Error(`Failed to delete assignment: ${error.message}`);
  }
};

// fetching completed test details
export const getCompletedAssignmentDetailsById = async (
  assignmentId,
  token
) => {
  const response = await fetch(
    `${BASE_URL}/sd/v1/getCompletedTestDetailsById/${assignmentId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.ok) {
    throw new Error(`Network response was not ok: ${response.statusText}`);
  }
  return response.json();
};

// deleting skill
export const deleteSkill = async (assignmentId, skillId, token) => {
  try {
    const response = await fetch(`${BASE_URL}/sd/v1/deleteSkill`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ assignmentId: assignmentId, skillId: skillId}),
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    } else {
      toast.success("Skill deleted successfully.");
    }
    return response.json();
  } catch (error) {
    throw new Error(`Failed to delete skill: ${error.message}`);
  }
};
