import React, { useEffect, useState, useRef, memo, useCallback } from "react";
import "./test.css";
import Speaker from "../../../Assets/Images/speaker.svg";
import activeSpeaker from "../../../Assets/Images/active-speaker.svg";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import TestFinishCard from "../TestFinishCard";
import questionTypeConfig from "../questionTypeConfig";
// import { emitData } from "../utils/emitSocketDataUtils";
import { parseLatexText, parseLatex } from "../../../Utils/LatexUtils";
import { emitStudentConnected } from "../utils/events";

// hook import
import { useDebounce } from "../../../hooks/useDebounce";
import { useWebSocket } from "../../../hooks/useWebSocket";
import { useIdleTimer } from "../../../hooks/useIdleTimer";

// component import
import OptionComponent from "./OptionComponent";
import QuestionComponent from "./QuestionComponent";
import Loader from "../../../Components/Loader/Loader";
import WarningMessage from "../../../Components/WarningMessage/WarningMessage";
//evenkts import
import { emitStudentTrack } from "../utils/events";

// image import
import pencilBoy from "../../../Assets/Images/pencilBoy.svg";

// redux action imports
import { startSkillPracticeApi } from "../../../redux/actions/skillPracticeAction";
import {
  startBenchmarkTest,
  benchmarkTestNextQuestion,
} from "../../../redux/actions/benchmarkTestAction";
import {
  startPlacementTest,
  resetTestState,
  placementTestNextQuestion,
} from "../../../redux/actions/placementTestActions";

// redux slice import
import { useAuthenticate } from "../../../hooks/useAuthenticate";
import ExplanationModalContent from "./ExplanationModalContent";
import InstructionCard from "../../../Components/WarningMessage/PlacementTestInstructionCard";
import { clearConfig } from "dompurify";

// lazy loading
const TabletPreviewNavBar = React.lazy(() => import("./TabletNavBar"));
const SideBarComponent = React.lazy(() => import("../SideBarComponent"));

const TestViewer = () => {
  // state
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState(null);
  const [inputData, setInputData] = useState([]);
  const [hideWarning, setHideWarning] = useState(false);
  const [tabSwitched, setTabSwitched] = useState(false);
  const [answeredCount, setAnsweredCount] = useState(0);
  const [startTime, setStartTime] = useState(Date.now());
  const [additionalTime, setAdditionalTime] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [endTestWarning, setEndTestWarning] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState(true);
  const [validationMessage, setValidationMessage] = useState(false);
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
  const [lastQuestionSkipped, setLastQuestionSkipped] = useState(false);
  const [questionStartTime, setQuestionStartTime] = useState(Date.now());
  const [totalTimeSpentInMs, setTotalTimeSpentInMs] = useState(null);
  const [lastQuestionSubmitted, setLastQuestionSubmitted] = useState(false);
  const [timeSpentPerQuestion, setTimeSpentPerQuestion] = useState(0);
  const [questionTime, setQuestionTime] = useState(0);
  const [speakingIndex, setSpeakingIndex] = useState(null);
  const [instructionCard, setInstructionCard] = useState(true);

  const debounceTime = 300;
  const statusRef = useRef();
  const [sessionTime, setSessionTime] = useState(
    parseInt(localStorage.getItem("sessionTime"), 10)
  );
  const {
    authResult,
    loading: authLoading,
    isAuthenticated,
  } = useAuthenticate();

  // custom hooks
  const { debounce } = useDebounce();
  // const { questionTime, questionTimeRef, setQuestionTime } = useQuestionTimer();

  localStorage.setItem("sessionTime", (0).toString());
  const queryParams = new URLSearchParams(window.location.search);
  let tdata = queryParams.get("token");
  const assignmentDetails = JSON.parse(
    decodeURIComponent(queryParams.get("assignmentDetails"))
  );

  const userData = {
    studentName: `${assignmentDetails.assignedTo.firstName} ${assignmentDetails.assignedTo.lastName}`,
    studentEmail: assignmentDetails.assignedTo.email,
  };
  const { socket } = useWebSocket(userData);

  const { isIdle, setIsIdle } = useIdleTimer(180000);
  const speechSynthesisInstance = window.speechSynthesis;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const authMsg = useSelector((state) => state.details.authErrMsg);
  const { sdaccessToken } = useSelector((state) => state.details);
  const {
    skillName,
    questionType,
    questionId,
    options,
    skippedQuestionIds,
    accuracyRate,
    testCompleted,
    attemptedQuestionsCount,
    isCompleted,
    totalTimeSpent,
    questionImage,
    questionText,
    textExplanation,
    createdAt,
    timeLeft,
  } = useSelector((state) => state.testViewReducer);

  const [textExplanationData, setTextExplanationData] = useState("");
  useEffect(() => {
    setTextExplanationData(textExplanation);
  }, [textExplanation]);

  // const textExplanation = {
  //   question: {
  //     text: {
  //       text: "Complete the sequence: 2, 4, <#@blank#>, 8.",
  //       image: null,
  //     },
  //   },
  //   yourAnswer: ["45"],
  //   correctAnswer: ["6"],
  //   isCorrect: false,
  //   options: [],
  //   questionExplanation:
  //     '<p><br><strong>QN-83678<br></strong><u><i><strong>Explanation</strong></i></u><u><strong><br></strong></u><u><i>With image </i></u><i><br></i><br><figure class="image"><img style="aspect-ratio:150/150;" src="file:///Users/sharansmacbookpro/Desktop/FS/98-CMS-MVP-1/qb-api-service/excelUploads/UpdateExplanationBatch/images/image-1.png" width="150" height="150"></figure><br></p>',
  //   explanationType: 0,
  //   timeSpent: 0,
  // };
  const [questionNumber, setQuestionNumber] = useState(() => {
    return (
      (skippedQuestionIds ? skippedQuestionIds.length : 0) +
      attemptedQuestionsCount +
      1
    );
  });
  const sessionRef = useRef(parseInt(localStorage.getItem("sessionTime"), 10));

  const [totalTime, setTotalTime] = useState(totalTimeSpent);
  // useRef
  const [hasTextBoxValue, setHasTextBoxValue] = useState(false);
  const timeRef = useRef(totalTimeSpent);
  const questionTimeRef = useRef(
    parseInt(localStorage.getItem("questionTime")) || 0
  );
  const inputRefs = useRef({});
  const viewBodyRef = useRef(null);
  const idleTimeoutRef = useRef(null);
  const prevAttemptedQuestionsCount = useRef(attemptedQuestionsCount);
  const intervalRef = useRef(null);
  const sessionIntervalRef = useRef(null);

  const pathname = location.pathname;
  const parts = pathname.split("/");
  const item = parts.pop();
  const item2 = parts.pop();
  const assignmentId = assignmentDetails._id;
  const assignmentType =
    assignmentDetails.assignment_type ??
    (item === "benchmark-test" ? 0 : item === "placement-test" ? 1 : 2);

  useEffect(() => {
    const isSkipped = skippedQuestionIds?.includes(questionId);
    const totalSkipped = skippedQuestionIds?.length || 0;
    if (isSkipped) {
      setQuestionNumber(totalSkipped + attemptedQuestionsCount);
    } else {
      setQuestionNumber(totalSkipped + attemptedQuestionsCount + 1);
    }
  }, [questionId, skippedQuestionIds, attemptedQuestionsCount, assignmentType]);

  useEffect(() => {
    let interval;
    if (
      !loading &&
      !isIdle &&
      !tabSwitched &&
      !validationMessage &&
      !isCompleted &&
      !endTestWarning
    ) {
      interval = setInterval(() => {
        setQuestionTime((prev) => prev + 1);
        localStorage.setItem("questionTime", questionTimeRef.current + 1);
        questionTimeRef.current += 1;
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [
    loading,
    isIdle,
    tabSwitched,
    validationMessage,
    isCompleted,
    endTestWarning,
  ]);

  useEffect(() => {
    const storedStartTime = localStorage.getItem("startTime");
    if (storedStartTime) {
      setStartTime(parseInt(storedStartTime, 10));
    } else {
      setStartTime(Date.now());
      localStorage.setItem("startTime", Date.now().toString());
    }
  }, []);

  // emit socket data
  const handleEmitData = async (accuracyRate, totalTimeSpent, status) => {
    const eventData = {
      student_name: `${assignmentDetails.assignedTo.firstName} ${assignmentDetails.assignedTo.lastName}`,
      subject: item2,
      assignment_type: assignmentDetails.assignment_type,
      assigned_on: assignmentDetails.createdAt ?? createdAt,
      current_session_time: sessionTime,
      current_question_time: questionTimeRef.current,
      total_time_spent: totalTimeSpent,
      student_email_id: assignmentDetails.assignedTo.email,
      accuracy_rate: item === "placement-test" ? "" : accuracyRate,
      assignment_status: status,
      test_status: socket ? socket.connected : false,
      socket_id: socket ? socket.id : "",
      question_details: {
        assignment_id: assignmentId,
        skill_name: skillName ?? "",
        question_number: questionNumber ?? "",
        question_id: questionId ?? "",
        is_completed: isCompleted,
        question: {
          text: questionText ?? "",
          image: questionImage ?? null,
        },
        options: options.map((option) => ({
          text: option.text ?? "",
          image: option.image ?? null,
        })),
      },
    };

    // console.log("Event Data:", eventData);
    // console.log("Socket:", socket);

    if (!socket || !socket.connected) {
      //  socket.connect();
      console.error("Socket is not connected.", socket);
      return;
    }

    try {
      await emitStudentTrack(socket, eventData);
      // console.log("Event emitted successfully", eventData);
    } catch (error) {
      console.error("Error emitting data:", error);
    }
  };

  useEffect(() => {
    const emitData = async () => {
      if (socket && socket.connected) {
        await handleEmitData(
          accuracyRate,
          totalTimeSpent,
          tabSwitched ? "paused" : "resumed"
        );
      } else {
        // console.log("Socket is not connected. reconnecting...");
      }
    };

    if (socket) {
      // Emit data as soon as the component is fully loaded (on connect)

      socket.on("connect", async () => {
        await emitData();
        socket.emit("studentConnect", userData);
      });

      // Emit data as soon as the teacherJoined and component is fully loaded (on connect)
      socket.on("teacherJoined", async () => {
        // console.log("Teacher joined");
        socket.emit("studentConnect", userData);
        await emitData();
      });

      // Cleanup the socket connect event listener on unmount or when socket changes
      return () => {
        socket.off("connect", emitData);
        socket.off("teacherJoined", emitData);
      };
    }
  }, [socket, accuracyRate, totalTimeSpent, tabSwitched]);

  useEffect(() => {
    // console.log("------outer timer started");
    // Clear any existing interval before setting a new one
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    if (!isCompleted && socket && socket.connected) {
      // Set an interval to emit data every 5 seconds
      intervalRef.current = setInterval(() => {
        // console.log("------timer started");
        if (socket && socket.connected) {
          handleEmitData(
            accuracyRate,
            totalTimeSpent,
            tabSwitched ? "paused" : "resumed"
          );
          // console.log("Data emitted at:", new Date().toISOString());
        } else {
          // console.log("Socket is not connected.", socket);
        }
      }, 5000); // Emit data every 5 seconds
    } else {
      // console.log("Socket is not connected or test is completed.");
    }

    // Cleanup the interval on unmount or when dependencies change
    return () => clearInterval(intervalRef.current);
  }, [isCompleted, socket, accuracyRate, totalTimeSpent, tabSwitched]);

  // Session Timer
  useEffect(() => {
    if (!loading && !isCompleted) {
      sessionIntervalRef.current = setInterval(() => {
        setSessionTime((prevTime) => {
          const newTime = prevTime + 5;
          localStorage.setItem("sessionTime", newTime.toString());
          return newTime;
        });
      }, 5000);
    }

    // Cleanup the session timer
    return () => {
      clearInterval(sessionIntervalRef.current);
    };
  }, [loading, isCompleted]);

  // check condition for submit button
  useEffect(() => {
    const blanks = questionText?.match(/<#@blank#>/g) || [];

    const hasValidInput =
      blanks.length > 0 &&
      blanks.length === inputData.length &&
      inputData.every((input) => input.trim() !== "");
    const hasValidSelection = selectedOptions.length > 0;
    setShowSubmitButton(hasValidInput || hasValidSelection || hasTextBoxValue);
  }, [inputData, selectedOptions, questionText, hasTextBoxValue]);

  useEffect(() => {
    const adjustHeight = () => {
      if (viewBodyRef.current) {
        viewBodyRef.current.style.height = "100%";
        const newHeight = viewBodyRef.current.scrollHeight;
        viewBodyRef.current.style.height = `${newHeight}px`;
      }
    };

    const debouncedAdjustHeight = debounce(adjustHeight, 100);
    const observer = new ResizeObserver(debouncedAdjustHeight);
    if (viewBodyRef.current) {
      observer.observe(viewBodyRef.current);
    }
    return () => {
      if (viewBodyRef.current) {
        observer.unobserve(viewBodyRef.current);
      }
    };
  }, [questionNumber, showSubmitButton, selectedOptions]);

  useEffect(() => {
    if (attemptedQuestionsCount !== prevAttemptedQuestionsCount.current) {
      prevAttemptedQuestionsCount.current = attemptedQuestionsCount;
    }
  }, [attemptedQuestionsCount]);

  const debouncedHandleBlur = debounce(() => {
    if (!tabSwitched) {
      setTabSwitched(true);
      statusRef.current = "paused";
      handleEmitData(accuracyRate, totalTimeSpent, statusRef.current);
    }
  }, debounceTime);

  // Then wrap the debounced function in useCallback
  const handleBlur = useCallback(() => {
    debouncedHandleBlur();
  }, [tabSwitched, debounceTime, accuracyRate, totalTimeSpent, handleEmitData]);

  const debouncedHandleFocus = debounce(() => {
    if (tabSwitched) {
      setTabSwitched(false);
      statusRef.current = "resumed";
      handleEmitData(accuracyRate, totalTimeSpent, statusRef.current);
    }
  }, debounceTime);

  // Then wrap the debounced function in useCallback
  const handleFocus = useCallback(() => {
    debouncedHandleFocus();
  }, [tabSwitched, debounceTime, accuracyRate, totalTimeSpent, handleEmitData]);

  useEffect(() => {
    window.addEventListener("blur", handleBlur);
    window.addEventListener("focus", handleFocus);

    return () => {
      window.removeEventListener("blur", handleBlur);
      window.removeEventListener("focus", handleFocus);
    };
  }, [handleBlur, handleFocus]);

  // benchmark test assignment click handler
  const fetchData = async () => {
    const skippedQuestion = localStorage.getItem("skippedId");
    setLoading(true);
    try {
      let response;
      if (assignmentType === 0) {
        if (skippedQuestion && skippedQuestion !== "0") {
          response = await dispatch(
            startBenchmarkTest(
              assignmentId,
              item2,
              sdaccessToken,
              skippedQuestion
            )
          );
        } else {
          response = await dispatch(
            startBenchmarkTest(assignmentId, item2, sdaccessToken)
          );
        }
      } else if (assignmentType === 1) {
        response = await dispatch(
          startPlacementTest(assignmentId, item2, sdaccessToken)
        );
      } else {
        if (skippedQuestion && skippedQuestion !== "0") {
          response = await dispatch(
            startSkillPracticeApi(
              skippedQuestion,
              assignmentId,
              [],
              0,
              0,
              sdaccessToken
            )
          );
        } else {
          response = await dispatch(
            startSkillPracticeApi("", assignmentId, [], 0, 0, sdaccessToken, 1)
          );
        }
      }
      if (response) {
        setData(response);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getAllFetchAssignmentRef = useRef(false);
  useEffect(() => {
    if (authResult) {
      setLoading(true);
      setQuestionStartTime(Date.now());
      dispatch(resetTestState());
      if (!getAllFetchAssignmentRef.current) {
        fetchData();
        getAllFetchAssignmentRef.current = true;
      }
    } else if (!authLoading) {
      setLoading(false);
    }
  }, [dispatch, authResult, authLoading]);

  const handleTimeTakenUpdate = (totalTimeInMs) => {
    setTotalTimeSpentInMs(totalTimeInMs);
  };

  const handleSkipQuestion = () => {
    setSessionTime((prevTime) => {
      const newTime = prevTime + questionTimeRef.current;
      localStorage.setItem("sessionTime", newTime.toString());

      return newTime;
    });

    setTotalTime((prevTime) => {
      const newTime = totalTimeSpent + questionTimeRef.current;
      timeRef.current = newTime;
      return newTime;
    });
    setLoading(true);
    setTimeSpentPerQuestion(totalTimeSpent + questionTimeRef.current);
    if (isCompleted === 1) {
      setLastQuestionSkipped(true);
    }
    localStorage.setItem("skippedId", 0);
    let response;
    if (
      questionTypeConfig.fillInTheBlanksType.includes(questionType) ||
      questionTypeConfig.textAnswerTypeInputBox.includes(questionType)
    ) {
      if (assignmentType === 0) {
        response = dispatch(
          benchmarkTestNextQuestion(
            questionId,
            assignmentId,
            [],
            1,
            questionTimeRef.current,
            item2,
            sdaccessToken
          )
        );
      } else if (assignmentType === 1) {
        response = dispatch(
          placementTestNextQuestion(
            questionId,
            assignmentId,
            [],
            1,
            questionTimeRef.current,
            item2,
            sdaccessToken || localStorage.getItem("currentSdAccessToken")
          )
        );
      } else {
        response = dispatch(
          startSkillPracticeApi(
            questionId,
            assignmentId,
            [],
            1,
            questionTimeRef.current,
            sdaccessToken
          )
        );
      }
    } else {
      if (assignmentType === 0) {
        response = dispatch(
          benchmarkTestNextQuestion(
            questionId,
            assignmentId,
            [],
            1,
            questionTimeRef.current,
            item2,
            sdaccessToken
          )
        );
      } else if (assignmentType === 1) {
        response = dispatch(
          placementTestNextQuestion(
            questionId,
            assignmentId,
            [],
            1,
            questionTimeRef.current,
            item2,
            sdaccessToken || localStorage.getItem("currentSdAccessToken")
          )
        );
      } else {
        response = dispatch(
          startSkillPracticeApi(
            questionId,
            assignmentId,
            [],
            1,
            questionTimeRef.current,
            sdaccessToken
          )
        );
      }
    }
    response.finally(() => {
      setLoading(false);
      setInputData([]);
      setSelectedOptions([]);
      setQuestionTime(0);
      questionTimeRef.current = 0;
      localStorage.setItem("questionTime", 0);
    });
  };

  const handleSubmitAnswer = (action) => {
    setSessionTime((prevTime) => {
      const newTime = prevTime + questionTimeRef.current;
      localStorage.setItem("sessionTime", newTime.toString());

      return newTime;
    });

    setTotalTime((prevTime) => {
      const newTime = totalTimeSpent + questionTimeRef.current;
      timeRef.current = newTime;
      return newTime;
    });

    setSubmitButtonClicked(true);
    setLoading(true);
    // const timeSpentOnQuestion = questionTimeRef.current;
    if (isCompleted === 1) {
      setLastQuestionSubmitted(true);
    }
    localStorage.setItem("skippedId", 0);

    let response;
    if (
      questionTypeConfig.fillInTheBlanksType.includes(questionType) ||
      questionTypeConfig.textAnswerTypeInputBox.includes(questionType)
    ) {
      if (assignmentType === 0) {
        response = dispatch(
          benchmarkTestNextQuestion(
            questionId,
            assignmentId,
            inputData,
            0,
            questionTimeRef.current,
            item2,
            sdaccessToken
          )
        );
      } else if (assignmentType === 1) {
        response = dispatch(
          placementTestNextQuestion(
            questionId || localStorage.getItem("currentQuestion"),
            assignmentId,
            inputData,
            0,
            questionTimeRef.current,
            item2,
            sdaccessToken || localStorage.getItem("currentSdAccessToken"),
            action ? 1 : 0
          )
        );
      } else {
        response = dispatch(
          startSkillPracticeApi(
            questionId,
            assignmentId,
            inputData,
            0,
            questionTimeRef.current,
            sdaccessToken,
            0
          )
        );
      }
    } else {
      if (assignmentType === 0) {
        response = dispatch(
          benchmarkTestNextQuestion(
            questionId,
            assignmentId,
            selectedOptions,
            0,
            questionTimeRef.current,
            item2,
            sdaccessToken
          )
        );
      } else if (assignmentType === 1) {
        response = dispatch(
          placementTestNextQuestion(
            questionId || localStorage.getItem("currentQuestion"),
            assignmentId,
            selectedOptions,
            0,
            questionTimeRef.current,
            item2,
            sdaccessToken || localStorage.getItem("currentSdAccessToken"),
            action ? 1 : 0
          )
        );
      } else {
        response = dispatch(
          startSkillPracticeApi(
            questionId,
            assignmentId,
            selectedOptions,
            0,
            questionTimeRef.current,
            sdaccessToken,
            0
          )
        );
      }
    }
    response.finally(() => {
      setLoading(false);
      setInputData([]);
      setSelectedOptions([]);
      setQuestionTime(0);
      questionTimeRef.current = 0;
      localStorage.setItem("questionTime", 0);
    });
  };

  const handleEndTest = () => {
    setHideWarning(true);
    let response;
    localStorage.setItem("skippedId", 0);
    localStorage.setItem("selectedAssignment", "null");
    localStorage.setItem("isCardClick", "true");
    if (isCompleted === 0) {
      if (assignmentType === 2) {
        response = dispatch(
          startSkillPracticeApi(
            "",
            assignmentId,
            [],
            0,
            questionTimeRef.current,
            sdaccessToken,
            1,
            1
          )
        );
      } else if (assignmentType === 1) {
        response = dispatch(
          startPlacementTest(
            assignmentId,
            item2,
            sdaccessToken,
            questionTimeRef.current
          )
        );
      } else {
        response = dispatch(
          startBenchmarkTest(
            assignmentId,
            item2,
            sdaccessToken,
            "",
            questionTimeRef.current
          )
        );
      }
    }
    localStorage.setItem("questionTime", 0);
    setEndTestWarning(false);
    let path = "";
    if (item2 === "math") {
      path = `/math/assignments`;
    } else {
      path = `/ela/language/assignments`;
    }
    navigate(path, { replace: true });
  };

  const handleSpeak = (text, index) => {
    if ("speechSynthesis" in window) {
      const replacedText = text
        .replace(/<#@blank#>/g, "blank")
        .replace(/_{2,}/g, "blank");

      const utterance = new SpeechSynthesisUtterance(
        parseLatexText(replacedText)
      );
      if (speechSynthesisInstance.speaking || speechSynthesisInstance.pending) {
        if (speakingIndex === index) {
          speechSynthesisInstance.cancel();
          setSpeakingIndex(null);
          return;
        } else {
          speechSynthesisInstance.cancel();
        }
      }
      setSpeakingIndex(index);
      speechSynthesisInstance.speak(utterance);
      utterance.onend = () => setSpeakingIndex(null);
    }
  };

  useEffect(() => {
    if (
      submitButtonClicked ||
      isIdle ||
      tabSwitched ||
      validationMessage ||
      endTestWarning
    ) {
      speechSynthesisInstance.cancel();
      setSpeakingIndex(null);
      setSubmitButtonClicked(false);
    }
  }, [
    submitButtonClicked,
    setSubmitButtonClicked,
    speechSynthesisInstance,
    isIdle,
    tabSwitched,
    validationMessage,
    endTestWarning,
  ]);

  // Explanation Modal
  // const handleExplanationModalOpen = () => {
  //   setIsModalOpen(true);
  // };
  const handleExplanationModalOpenClose = () => {
    setIsModalOpen(!isModalOpen);
  };

  // Function to convert seconds to MM:SS format
  const formatTime = (seconds) => {
    if (seconds < 60) {
      return `0 min ${seconds} sec`;
    }
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes} min ${secs} sec`;
  };

  const handleConfirmationClick = (confirm) => {
    setIsIdle(false);
    clearTimeout(idleTimeoutRef.current);
    if (confirm) {
      handleEndTest();
    } else {
      setHideWarning(true);
      setTimeout(() => {
        setValidationMessage(false);
        setEndTestWarning(false);
        setTabSwitched(false);
      }, 400);
    }
  };

  const handleInputChange = (updatedValues) => {
    setInputData(updatedValues);
  };

  return (
    <div
      className="test-viewer-bg-container"
      // style={{ border: "2px solid black", height: "600px" }}
    >
      {authLoading ? (
        <Loader />
      ) : isAuthenticated ? (
        <div
          className="d-flex justify-content-around gap-1 "
          // style={{ border: "5px solid black" }}
        >
          <div className="overflow-auto d-flex flex-column h-100 vh-100 test-view-container ">
            {instructionCard && <InstructionCard close={setInstructionCard} />}
            {loading && <Loader />}
            <div>
              {/* <TabletPreviewNavBar
              startTime={startTime}
              setStartTime={setStartTime}
              assignmentId={assignmentId}
              skippedCount={
                skippedQuestionIds?.length + (lastQuestionSkipped ? 1 : 0)
              }
              skippedQuestionsMap={skippedQuestionIds}
              answeredCount={answeredCount}
              sdaccessToken={sdaccessToken}
              item2={item2}
              assignmentType={assignmentType}
            /> */}
            </div>
            {assignmentType !== 1 && isIdle && (
              <WarningMessage
                showButton1={true}
                showButton2={true}
                hideWarning={hideWarning}
                message={"Hey, Do you want to resume the test?"}
                handleConfirmationClick={handleConfirmationClick}
              />
            )}
            {assignmentType !== 1 && tabSwitched && (
              <WarningMessage
                message={
                  "You are either trying to close this tab or switch to another tab. The test will end!"
                }
                showButton1={true}
                showButton2={true}
                handleConfirmationClick={handleConfirmationClick}
                hideWarning={hideWarning}
              />
            )}
            {assignmentType !== 1 && validationMessage && (
              <WarningMessage
                showButton1={false}
                showButton2={true}
                hideWarning={hideWarning}
                handleConfirmationClick={handleConfirmationClick}
                message={"Select at least one option to submit successfully."}
              />
            )}
            {isModalOpen &&
              !loading &&
              textExplanationData !== null &&
              textExplanationData !== "" &&
              textExplanationData !== undefined && (
                <div
                  className="modal-backdrop d-flex justify-content-center align-items-center rounded-2 p-5"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="custom-modal-container">
                    <ExplanationModalContent
                      handleExplanationModalClose={
                        handleExplanationModalOpenClose
                      }
                      assignmentId={assignmentId}
                      handleSpeak={handleSpeak}
                      textExplanation={textExplanationData}
                      setTextExplanationData={setTextExplanationData}
                      speakingIndex={speakingIndex}
                      isCompleted={isCompleted}
                      questionId={questionId}
                    />
                  </div>
                </div>
              )}
            {isCompleted === 1 && !isModalOpen && (
              <div style={{ overflowX: "hidden" }}>
                <TestFinishCard
                  programName={item2}
                  completionMessage={
                    assignmentType === 1 && timeLeft === 0
                      ? "Sorry, but it looks like the test has ended because 40 minutes have passed."
                      : "Test ended successfully. No more questions to show."
                  }
                />
              </div>
            )}
            {!testCompleted && !loading && isCompleted !== 1 && data && (
              <div className="d-flex justify-content-center align-items-center h-100">
                <div
                  className={`view-body  ${
                    questionTypeConfig.readingComprehensionType.includes(
                      questionType
                    )
                      ? "wide"
                      : ""
                  }`}
                  // ref={viewBodyRef}
                  style={{ minWidth: "99%" }}
                >
                  <span
                    className={`view-body-header ${
                      questionTypeConfig.readingComprehensionType.includes(
                        questionType
                      )
                        ? "wide"
                        : ""
                    }`}
                  ></span>
                  <div className="skill-container">
                    <div className="skill-name-container">
                      {skillName && (
                        <>
                          <span>Skill:</span>
                          <span className="fw-semi-bold ">{skillName}</span>
                        </>
                      )}
                    </div>
                    <span className="question-counter fw-bold ">
                      {" "}
                      Question No: {questionNumber}
                    </span>
                  </div>
                  <QuestionComponent
                    onInputChange={handleInputChange}
                    questionType={questionType}
                    questionImage={questionImage}
                    questionText={questionText}
                    handleSpeak={handleSpeak}
                    speakingIndex={speakingIndex}
                  />
                  {questionType !== "N" && (
                    <OptionComponent
                      onTextInputChange={handleInputChange}
                      setSelectedOptions={setSelectedOptions}
                      setHasTextBoxValue={setHasTextBoxValue}
                      setInputData={setInputData}
                      handleSpeak={handleSpeak}
                      speakingIndex={speakingIndex}
                    />
                  )}

                  <div
                    className="view-footer d-flex justify-content-between align-items-center 
                  fw-medium position-relative mt-auto mb-4"
                  >
                    <span
                      className="skip-question-button"
                      onClick={handleSkipQuestion}
                    >
                      <u>Skip the question</u>
                    </span>
                    <button
                      className="submit-answer-button rounded-1 text-white fs-7"
                      onClick={() => {
                        if (assignmentType === 2) {
                          handleExplanationModalOpenClose();
                          handleSubmitAnswer();
                        } else {
                          handleSubmitAnswer();
                        }
                      }}
                      disabled={!showSubmitButton}
                      data-tooltip={
                        !showSubmitButton ? "Please answer to submit" : ""
                      }
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            )}

            {isCompleted !== 1 && data && !isModalOpen && (
              <img
                src={pencilBoy}
                className="cartoon-character position-fixed flex-shrink-0 "
                alt="cartoon"
              />
            )}
          </div>
          {/* sidebar component */}
          {isCompleted !== 1 && data && (
            <div className="test-view-sidebar">
              <SideBarComponent
                item2={item2}
                isIdle={isIdle}
                loading={loading}
                startTime={startTime}
                setLoading={setLoading}
                isCompleted={isCompleted}
                tabSwitched={tabSwitched}
                accuracyRate={accuracyRate}
                setStartTime={setStartTime}
                assignmentId={assignmentId}
                sdaccessToken={sdaccessToken}
                answeredCount={answeredCount}
                testCompleted={testCompleted}
                additionalTime={additionalTime}
                totalTimeSpent={totalTimeSpent}
                skippedQuestions={skippedQuestionIds}
                validationMessage={validationMessage}
                currentQuestionNumber={questionNumber}
                lastQuestionSkipped={lastQuestionSkipped}
                skippedCount={
                  skippedQuestionIds?.length + (lastQuestionSkipped ? 1 : 0)
                }
                handleTimeTakenUpdate={handleTimeTakenUpdate}
                lastQuestionSubmitted={lastQuestionSubmitted}
                attemptedQuestionsCount={
                  attemptedQuestionsCount + (lastQuestionSubmitted ? 1 : 0)
                }
                assignmentType={assignmentType}
                handleEndTest={handleEndTest}
                handleSubmitAnswer={handleSubmitAnswer}
                questionId={questionId}
                submitButtonClicked={submitButtonClicked}
                endTestWarning={endTestWarning}
                setEndTestWarning={setEndTestWarning}
                isModelOpen={isModalOpen}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="auth-err-msg">{authMsg}</div>
      )}
    </div>
  );
};

export default memo(TestViewer);
