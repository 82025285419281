import React, { useState, useCallback } from 'react';
import speaker from "../../Assets/Images/speaker.svg";
import activeSpeaker from "../../Assets/Images/active-speaker.svg";
import { parseLatex } from '../../Utils/LatexUtils';
import questionTypeConfig from '../../Pages/Test/questionTypeConfig';

const LibraryQuestionComponent = (
    {
        data,
        handleSpeak,
        speakingIndex,
        handleOptionSelection,
        selectedOption,
        handleAnsweringBlanks,
        handleSubmitAnswer
    }
) => {
    const [blankInputs, setBlankInputs] = useState([]);

    const handleBlankInputChange = (index, value) => {
        setBlankInputs((prevInputs) => {
            const updatedInputs = [...prevInputs];
            updatedInputs[index] = value.trim();
            handleAnsweringBlanks(updatedInputs);
            return updatedInputs;
        });
    };

    const renderQuestionText = (text) => {
        const parts = text.split(/<#@blank#>/);
        return parts.map((part, index) => (
            <React.Fragment key={index}>
                <span dangerouslySetInnerHTML={{ __html: parseLatex(part) }} />
                {index < parts.length - 1 && (
                    <input
                        type="text"
                        className={`blank-input me-1 ms-1 bg-transparent border-0 border-bottom text-center`}
                        disabled={(data.currentQuestion.options.length > 0 &&
                            questionTypeConfig.textAnswerTypeInputBox.includes(data.currentQuestion.qtype)) ?
                            true : false}
                            onChange={(data.currentQuestion.options.length === 0 ||
                                questionTypeConfig.textAnswerTypeInputBox.includes(data.currentQuestion.qtype) ||
                                questionTypeConfig.fillInTheBlanksType.includes(data.currentQuestion.qtype) ||
                                questionTypeConfig.fillInTheBlanksWithImageType.includes(data.currentQuestion.qtype)) ?
                                (e) => handleBlankInputChange(index, e.target.value) : null}
                            value={blankInputs[index] || ''}
     

                            // onChange={(data.currentQuestion.options.length === 0 ||
                        //     !questionTypeConfig.textAnswerTypeInputBox.includes(data.currentQuestion.qtype)) ?
                        //     (e) => handleBlankInputChange(index, e.target.value) : null}
                        // value={blankInputs[index] || ''}
                    />
                )}
            </React.Fragment>
        ));
    };

    const capitalizeFirstForTrueFalse = useCallback((text) => {
        const lowerText = text?.toLowerCase().trim();
        return lowerText === "true" || lowerText === "false"
            ? lowerText.toUpperCase()
            : text;
    }, []);

    return (
        <div className='d-flex flex-column gap-3 pe-3 library-question-container'>
            <div>
                {data.currentQuestion?.skillTitle && (
                    <div className='d-flex gap-1 pb-1 library-skill-container'>
                        <div>Skill Title:</div>
                        <div>{data.currentQuestion.skillTitle}</div>
                    </div>
                )}
                {data.currentQuestion?.passageText && (
                    <div className='d-flex'>
                        <img
                            className="library-speaker-custom-styling mt-1"
                            src={speakingIndex === "passageQuestionText" ? activeSpeaker : speaker}
                            onClick={() => handleSpeak(data.currentQuestion?.passageText, "passageQuestionText")}
                            alt="Speaker"
                        />
                        <div >
                            {renderQuestionText(parseLatex(data.currentQuestion?.passageText))}
                        </div>
                    </div>
                )}
                <div className='d-flex gap-1 align-items-start'>
                    <img
                        className={`library-speaker-custom-styling  ${data.currentQuestion?.question.text.includes('<math-field>') ? 'mt-2' : 'mt-1'}`}
                        src={speakingIndex === "questionText" ? activeSpeaker : speaker}
                        onClick={() => handleSpeak(data.currentQuestion?.question.text, "questionText")}
                        alt="Speaker"
                    />
                    <div className="fw-bold">
                        {renderQuestionText(parseLatex(data.currentQuestion?.question.text))}
                    </div>
                </div>
            </div>
            {data.currentQuestion?.question.image && (
                <div className='d-flex justify-content-center' style={{ width: "75%" }}>
                    <img
                        className='library-question-image align-content-center'
                        src={data.currentQuestion?.question.image}
                        alt="Question Img"
                        loading='lazy'
                    />
                </div>)}
            <div className='d-flex gap-2 flex-wrap library-options-wrapper'>
                {questionTypeConfig.textAnswerTypeInputBox.includes(data?.currentQuestion?.qtype) ?
                    <div className='d-flex align-items-center'
                        style={{ width: "50%" }}>
                        <input
                            type="text"
                            value={selectedOption[0]}
                            className="text-options mt-0"
                            placeholder="Enter answer"
                            onChange={(e) => handleAnsweringBlanks(e.target.value)}
                        />
                    </div> :
                    !questionTypeConfig.fillInTheBlanksType.includes(data.currentQuestion?.qtype) && 
                        data.currentQuestion?.options.map((option, index) => (
                        <div
                            className='d-flex gap-1 align-items-center library-options-container'
                            key={option._id}
                            onClick={() => handleOptionSelection(option._id)}
                        >
                            {option.text && (
                                <img
                                    className='library-speaker-custom-styling'
                                    src={speakingIndex === `optionText-${index}` ? activeSpeaker : speaker}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleSpeak(option.text, `optionText-${index}`);
                                    }}
                                    alt="Speaker"
                                />
                            )}
                            <div
                                className='d-flex p-2 gap-2 library-option-container justify-content-center 
                                    align-items-center h-100'
                                style={{
                                    outline: Array.isArray(selectedOption)
                                        ? selectedOption.includes(option._id)
                                            ? "1px solid #347bfa"
                                            : ""
                                        : selectedOption === option._id
                                            ? "1px solid #347bfa"
                                            : "",
                                }}
                            >
                                {questionTypeConfig.isSingleSelect.includes(data.currentQuestion.qtype) ? (
                                    <input
                                        type='radio'
                                        name="options"
                                        className='option-radio'
                                        checked={Array.isArray(selectedOption) &&
                                            selectedOption.includes(option._id)}
                                    />
                                ) : (
                                    <input
                                        type='checkbox'
                                        name="options"
                                        className='library-select-checkbox'
                                        checked={Array.isArray(selectedOption) &&
                                            selectedOption.includes(option._id)}
                                    />
                                )}

                                {option.image ? (
                                    <div>
                                        <img
                                            className='library-option-image'
                                            src={option.image}
                                            alt="Question Img"
                                            loading='lazy'
                                        />
                                        {option.text && (
                                            <div
                                                className='d-flex align-items-center'
                                                style={{ width: "90%" }}
                                                dangerouslySetInnerHTML={{
                                                    __html: parseLatex(capitalizeFirstForTrueFalse(option.text)),
                                                }}
                                            />
                                        )}
                                    </div>
                                ) : (
                                    option.text && (
                                        <div
                                            className='d-flex align-items-center'
                                            style={{ width: "90%" }}
                                            dangerouslySetInnerHTML={{
                                                __html: parseLatex(capitalizeFirstForTrueFalse(option.text)),
                                            }}
                                        />
                                    )
                                )}
                            </div>
                        </div>
                    ))}
            </div>

            <div className='d-flex p-3 justify-content-end align-self-end'>
                <button
                    className='p-1 pe-3 ps-3 library-question-submit'
                    onClick={() => {
                        if (selectedOption.length) {
                            handleSubmitAnswer();
                        }
                    }}
                    disabled={selectedOption.length === 0 ? true : false}
                    style={{
                        backgroundColor: selectedOption.length === 0 ? "#83A1BE" : "",
                        border: selectedOption.length === 0 ? "1px solid #83A1BE" : "",
                        cursor: selectedOption.length === 0 ? "not-allowed" : "pointer"
                    }}
                >
                    Submit
                </button>
            </div>
        </div>
    );
}

export default LibraryQuestionComponent;
