import React, { useState, useEffect, useCallback, useMemo } from 'react';
import './GradeWiseDetailedReport.css';
import { FaPlay } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchMasteryByLevelId } from '../../Utils/ApiHandler/ProgressReportApi';
import Loader from '../Loader/Loader';
import { useAuthenticate } from "../../hooks/useAuthenticate";

const GradeWiseDetailedReport = () => {
    const [expandedItems, setExpandedItems] = useState({ domains: [], topics: [] });
    const [masteryData, setMasteryData] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const pathname = location.pathname;

    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const grade = queryParams.get('grade');
    const gradeId = queryParams.get('gradeId');
    const masteryType = queryParams.get('type');
    const authToken = useSelector((state) => state.details.sdaccessToken);
    const programs = useSelector((state) => state.details.enrolledPrograms);
    const { authResult, loading: authLoading } = useAuthenticate();

    const getProgramId = useCallback(() => {
        const programMapping = { language: "ELA", math: "Math", reading: "Reading" };
        const pathSegment = pathname.split("/").slice(-3)[0];
        const programTitle = programMapping[pathSegment.toLowerCase()];
        const program = programs.find((p) => p.title === programTitle);
        return program ? program._id : "";
    }, [pathname, programs]);

    useEffect(() => {
        const fetchData = async () => {
            if (!authLoading && authResult) {
                const programId = getProgramId();
                if (programId && gradeId) {
                    setLoading(true);
                    try {
                        const data = await fetchMasteryByLevelId(gradeId, programId, authToken, masteryType);
                        setMasteryData(data?.data);
                    } catch (error) {
                        console.error("Error fetching mastery data:", error);
                    } finally {
                        setLoading(false);
                    }
                } else {
                    setLoading(false);
                }
            }
        };

        fetchData();
    }, [authResult, authLoading, gradeId, masteryType, authToken]);

    const toggleExpand = useCallback((type, identifier) => {
        setExpandedItems(prevState => ({
            ...prevState,
            [type]: prevState[type].includes(identifier)
                ? prevState[type].filter(item => item !== identifier)
                : [...prevState[type], identifier]
        }));
    }, []);

    const handleBackClick = useCallback(() => navigate(-1), [navigate]);

    const getPlayButtonClass = (identifier, type) =>
        expandedItems[type].includes(identifier) ? 'play-button-rotated' : '';

    const formatGWScore = (score) => {
        if (score === null || score === undefined || isNaN(score)) {
            return "N/A";
        }
        const formattedScore = Math.abs(score);
        return formattedScore % 1 === 0 ? formattedScore.toFixed(0) : formattedScore.toFixed(1);
    };

    const getScoreClass = (score) => {
        return Math.abs(score) < 50 ? "low-score" : "high-score";
    };

    if (loading) return <Loader />;

    return (
        <div className='d-flex justify-content-center align-items-center'>
            <div className='gw-wrapper'>
                <div className='gw-main-wrapper d-flex justify-content-center align-items-center'>
                    <div className='gw-main-container'>
                        <div className='gw-header d-flex justify-content-between align-items-center p-4'>
                            <div className='gw-grade'>Grade {grade}</div>
                            <div className='gw-overall-score-wrapper d-flex justify-content-center align-items-center'>
                                <div className='gw-overall-score'>OVERALL LEVEL SCORE</div>
                                <div className={`gw-overall-score-percentage ${getScoreClass(masteryData?.groupedLevel[0]?.averageLevelScore)}`}>
                                    {masteryData?.groupedLevel[0]?.averageLevelScore
                                        ? `${formatGWScore(Math.abs(masteryData.groupedLevel[0].averageLevelScore))}%`
                                        : "0%"}
                                </div>
                            </div>
                        </div>
                        <div className='gw-divider'></div>
                        {masteryData?.groupedLevel?.length > 0 ? (
                            masteryData.groupedLevel.map(level => (
                                <div key={level.levelId}>
                                    {level.domains.map(domain => (
                                        <React.Fragment key={domain.domainId}>
                                            <div className='gw-domain-wrapper d-flex justify-content-between align-items-center p-4'
                                                onClick={() => toggleExpand('domains', domain.domainTitle)}
                                            >
                                                <FaPlay
                                                    className={`results-play-button ${getPlayButtonClass(domain.domainTitle, 'domains')}`}
                                                    onClick={() => toggleExpand('domains', domain.domainTitle)}
                                                />
                                                <div className='gw-domain-text'>{domain.domainTitle}</div>
                                                <div className={`gw-domain-score ${getScoreClass(domain.averageDomainScore)}`}>
                                                    {formatGWScore(Math.abs(domain.averageDomainScore || 0))}%
                                                </div>
                                            </div>
                                            {expandedItems.domains.includes(domain.domainTitle) && domain.topics.map(topic => (
                                                <React.Fragment key={topic.topicId}>
                                                    <div className='gw-topic-wrapper d-flex justify-content-between align-items-center p-4'
                                                        onClick={() => toggleExpand('topics', topic.topicTitle)}
                                                    >
                                                        <FaPlay
                                                            className={`results-play-button ${getPlayButtonClass(topic.topicTitle, 'topics')}`}
                                                            onClick={() => toggleExpand('topics', topic.topicTitle)}
                                                        />
                                                        <div className='gw-topic-text'>{topic.topicTitle}</div>
                                                        <div className={`gw-topic-score ${getScoreClass(topic.averageTopicScore)}`}>
                                                            {formatGWScore(Math.abs(topic.averageTopicScore || 0))}%
                                                        </div>
                                                    </div>
                                                    {expandedItems.topics.includes(topic.topicTitle) && topic.skills.map(skill => (
                                                        <div key={skill.skillId} className='gw-skill-wrapper d-flex justify-content-between align-items-center p-4'>
                                                            <div className='gw-skill-text'>{skill.skillTitle}</div>
                                                            <div className={`gw-skill-score ${getScoreClass(skill.skillScore)}`}>
                                                                {formatGWScore(Math.abs(skill.skillScore || 0))}%
                                                            </div>
                                                        </div>
                                                    ))}
                                                </React.Fragment>
                                            ))}
                                        </React.Fragment>
                                    ))}
                                </div>
                            ))
                        ) : (
                            <div className='gw-no-details-message text-center'>No details available</div>
                        )}
                    </div>
                </div>
                <div className={`gw-button-container d-flex justify-content-end`} style={{ marginRight: masteryData?.groupedLevel?.length > 0 ? '0.8rem' : '2rem' }}>
                    <button className='gw-back-button' onClick={handleBackClick}>Back</button>
                </div>
            </div>
        </div>
    );
};
export default GradeWiseDetailedReport;