import { configureStore } from "@reduxjs/toolkit";
import detailsReducer from "../redux/slices/studentDetails/studentDetailsSlice";
import testViewReducer from "./reducers/testViewReducer";
import assignmentReducer from "../redux/slices/assignments/assignmentsSlice";
import iconReducer from "../redux/slices/icons/iconsSlice";
import flagCommentReducer from "../redux/slices/flagQuestionCommentSlice/flagQuestionCommentSlice";
import libraryReducer from "../redux/slices/library/librarySlice";
import writingTestReducer from "../redux/slices/writing/writingTestSlice";
import spellingReducer from "../redux/slices/spellingSlice/spellingSlice";
import vocabularyReducer from '../redux/slices/vocabularySlice/vocabularySlice'
const store = configureStore({
  reducer: {
    assignments: assignmentReducer,
    icons: iconReducer,
    details: detailsReducer,
    testViewReducer: testViewReducer,
    flagComment: flagCommentReducer,
    writing: writingTestReducer,
    library: libraryReducer,
    spelling:spellingReducer,
    vocabulary:vocabularyReducer
  },
});

export default store;
