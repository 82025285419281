import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Tooltip } from "bootstrap";
import "../../../Components/ViewCompletedDetails/questionDetails.css";
import { parseLatex } from "../../../Utils/LatexUtils";
import Speaker from "../../../Assets/Images/speaker.svg";
import activeSpeaker from "../../../Assets/Images/active-speaker.svg";
import AddCommentModal from "../../../Components/AddCommentModal/AddCommentModal";
import questionTypeConfig from "../questionTypeConfig";

const ExplanationModalContent = ({
  questionId,
  assignmentId,
  isFlag,
  fetchTestDetails,
  handleExplanationModalClose,
  handleSpeak,
  textExplanation,
  setTextExplanationData,
  speakingIndex,
  isCompleted = false,
  isDetailsModal = false,
  getSpellingAndVocabularyDetails,
  cmsQuestionId,
  practiceSessionId,
  questionNo,
  levelOrder
}) => {
  const [isCommentModalOpen, setCommentModalOpen] = useState(false);
  const { staffAccess } = useSelector((state) => state.icons);
  const location = useLocation();
  const isSpelling = location.pathname.includes("spelling");
  const isVocabulary = location.pathname.includes("vocabulary");
  const isLibrary = location.pathname.includes("library");
  const [isFlagged, setIsFlagged] = useState(false);

  

  const handleUpdateFlagStatus = (flagStatus) => {
    setIsFlagged(flagStatus);
  };

  // Function to convert seconds to MM:SS format
  const formatTime = (seconds) => {
    if (seconds < 60) {
      return `0 min ${seconds} sec`;
    }
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes} min ${secs} sec`;
  };

  const capitalizeFirstForTrueFalse = useCallback((text) => {
    const lowerText = text?.toLowerCase().trim();
    return lowerText === "true" || lowerText === "false"
      ? lowerText.toUpperCase()
      : text;
  }, []);

  const handleCommentSubmit = () => {
    setCommentModalOpen(false);
  };

  // Initialize all tooltips on the page
  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    );
    tooltipTriggerList.forEach((tooltipTriggerEl) => {
      new Tooltip(tooltipTriggerEl);
    });
  }, [isFlag, isFlagged]);

  return (
    <div className="custom-modal-content">
      {isCommentModalOpen && !isLibrary && (
        <AddCommentModal
          onSubmit={handleCommentSubmit}
          onClose={() => {
            setCommentModalOpen(false);
          }}
          questionId={questionId}
          assignmentId={assignmentId}
          fetchTestDetails={fetchTestDetails}
          getSpellingAndVocabularyDetails={getSpellingAndVocabularyDetails}
          cmsQuestionId={cmsQuestionId}
          practiceSessionId={practiceSessionId}
          isFlag={isFlag}
          handleUpdateFlagStatus={handleUpdateFlagStatus}
        />
      )}
      <div className="modal-body" style={{ padding: 0 }}>
        <>
          <div
            className={`alert ${
              textExplanation?.isCorrect
                ? "correct-question"
                : "incorrect-question"
            } d-flex justify-content-between gap-4 align-items-center`}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {textExplanation?.isCorrect ? (
                <i className="bi bi-check-circle-fill me-2"></i>
              ) : textExplanation?.questionStatus === 2 ? (
                <i class="bi bi-question-circle me-2"></i>
              ) : (
                <i className="bi bi-x-circle-fill me-2"></i>
              )}
              <strong>
                {textExplanation?.isCorrect
                  ? "Correct Answer"
                  : textExplanation?.questionStatus === 2
                  ? "Skipped Question"
                  : "Wrong Answer"}
              </strong>
            </div>
            <div className="d-flex align-items-center gap-5">
              {staffAccess && (
                !isLibrary && (
                <i
                  className={`bi bi-flag-fill text-${
                    isFlag || isFlagged ? "danger" : "secondary"
                  }`}
                  style={{
                    cursor: isFlag || isFlagged ? "not-allowed" : "pointer",
                  }}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title={
                    isFlag || isFlagged
                      ? "Already flagged"
                      : "Flag this question"
                  }
                  onClick={() => {
                    if (!(isFlag || isFlagged)) {
                      setCommentModalOpen(true);
                    }
                  }}
                ></i>)
              )}

              <div className="d-flex justify-content-between gap-2 align-items-center">
                <span
                  className={`ms-auto border border-${
                    textExplanation?.isCorrect
                      ? "success"
                      : textExplanation?.questionStatus === 2
                      ? "secondary"
                      : "danger"
                  } bg-white text-dark rounded p-2`}
                >
                  {textExplanation?.timeSpent &&
                    formatTime(textExplanation?.timeSpent)}
                </span>
              </div>
            </div>
          </div>
          <div className="p-4 d-flex flex-column gap-2">
            {(isSpelling || isVocabulary) && (
              <div>
                <div className="spell-and-vocab-level">Grade : {levelOrder}</div>
                <div className="spell-and-vocab-question">
                  Question No: {questionNo}
                </div>
              </div>
            )}
            {textExplanation?.passage && (
              <div className="explanation-passage">
                <div
                  dangerouslySetInnerHTML={{ __html: textExplanation?.passage }}
                  className="reading-passage"
                />
              </div>
            )}
            <div className="d-flex gap-3 align-items-start">
              <img
                className={`speaker-icon ${
                  speakingIndex === "textExplanationQuestion" ? "active" : ""
                }`}
                src={
                  speakingIndex === "textExplanationQuestion"
                    ? activeSpeaker
                    : Speaker
                }
                onClick={() =>
                  handleSpeak(
                    isSpelling || isVocabulary
                      ? textExplanation?.question
                      : textExplanation?.question?.text?.text,
                    "textExplanationQuestion"
                  )
                }
                alt="Speaker"
              />
              {/* <h6
                dangerouslySetInnerHTML={{
                  __html: parseLatex(textExplanation?.question?.text?.text)
                    .split("<#@blank#>")
                    .join(" _____ "),
                }}
              ></h6> */}
              {isSpelling ? (
                <div className="d-flex align-items-center gap-3">
                  <span
                    className="spell-play-audio"
                    onClick={() =>
                      handleSpeak(
                        textExplanation?.question,
                        "textExplanationQuestion"
                      )
                    }
                  >
                    Play Audio
                  </span>
                </div>
              ) : (
                <h6
                  dangerouslySetInnerHTML={{
                    __html: parseLatex(
                      isVocabulary
                        ? textExplanation?.question
                        : textExplanation?.question?.text?.text
                    )
                      .split("<#@blank#>")
                      .join(" _____ "),
                  }}
                ></h6>
              )}
            </div>
            <div>
              {textExplanation?.question?.text?.image && (
                <div className="d-flex justify-content-center rounded">
                  <img
                    src={textExplanation?.question?.text?.image}
                    alt="Question"
                    className="img-fluid"
                    width={250}
                  />
                </div>
              )}
            </div>

            {!questionTypeConfig.fillInTheBlanksType.includes(
              textExplanation?.qtype
            ) &&
            !questionTypeConfig.textAnswerTypeInputBox.includes(
              textExplanation?.qtype
            ) &&
            !questionTypeConfig.fillInTheBlanksWithImageType.includes(
              textExplanation?.qtype
            ) &&
            textExplanation?.options?.length > 0 ? (
              <div className="options-container">
                <div className=" d-flex flex-wrap gap-4 justify-content-center">
                  {textExplanation?.options.map((item, index) => {
                    const isCorrect = item.isCorrect;

                    return (
                      <div className="col option-item" key={item._id}>
                        <div
                          className={` rounded d-flex justify-content-between`}
                        >
                          <div className="flex-fill d-flex flex-column gap-1">
                            {item?.image && (
                              <div
                                className={`d-flex justify-content-center rounded p-2 ${
                                  textExplanation?.yourAnswer?.includes(
                                    item._id
                                  )
                                    ? item.isCorrect
                                      ? "submitted-correct-option"
                                      : "submitted-incorrect-option"
                                    : "not-submitted-option"
                                }`}
                              >
                                <img
                                  src={item?.image}
                                  alt="Option"
                                  className="img-fluid"
                                  width={250}
                                />
                                <i
                                  className="bi bi-check-circle-fill text-success p-2"
                                  style={{
                                    visibility: isCorrect
                                      ? "visible"
                                      : "hidden",
                                  }}
                                ></i>
                              </div>
                            )}
                            {item?.text && (
                              <div className="d-flex justify-content-between align-items-start gap-1">
                                <img
                                  className="p-2 ps-0"
                                  src={
                                    speakingIndex === index
                                      ? activeSpeaker
                                      : Speaker
                                  }
                                  onClick={() => handleSpeak(item.text, index)}
                                  alt="Speaker"
                                />
                                <div
                                  className={`flex-fill p-2 rounded  ${
                                    (isVocabulary
                                      ? textExplanation?.submittedAnswer
                                      : textExplanation?.yourAnswer
                                    )?.includes(item._id)
                                      ? item.isCorrect
                                        ? "submitted-correct-option"
                                        : "submitted-incorrect-option"
                                      : "not-submitted-option"
                                  }`}
                                >
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: parseLatex(
                                        capitalizeFirstForTrueFalse(item.text)
                                      ),
                                    }}
                                  />
                                </div>

                                <i
                                  className="bi bi-check-circle-fill text-success p-2"
                                  style={{
                                    visibility: item?.image
                                      ? "hidden"
                                      : isCorrect
                                      ? "visible"
                                      : "hidden",
                                  }}
                                ></i>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div
                className="d-flex flex-column p-2 gap-2"
                style={{ fontWeight: "bold" }}
              >
                {!textExplanation?.isCorrect &&
                  textExplanation?.questionStatus !== 2 && (
                    <div
                      className="submitted-input-answer"
                      style={{
                        color: "red",
                        paddingLeft: "1.75rem",
                      }}
                    >
                      Your Answer :{" "}
                      {parseLatex(
                        isSpelling && textExplanation?.submittedAnswer
                          ? textExplanation.submittedAnswer.join(", ")
                          : textExplanation?.yourAnswer?.join(", ")
                      )}
                    </div>
                  )}
                <div
                  className="correct-input-answer"
                  style={{
                    color: "green",
                    paddingLeft: "1.75rem",
                  }}
                >
                  Correct Answer :{" "}
                  {parseLatex(
                    textExplanation?.correctAnswer &&
                      textExplanation?.correctAnswer?.join(", ")
                  )}
                </div>
              </div>
            )}
            {textExplanation?.questionExplanation && (
              <div className="d-flex flex-column p-4 align-items-center">
                <strong className=" fs-6">Explanation</strong>
                <p
                  dangerouslySetInnerHTML={{
                    __html: textExplanation?.questionExplanation,
                  }}
                ></p>
              </div>
            )}
          </div>
          <div
            className="modal-footer"
            style={{
              padding: 0,
              margin: "1rem 0",
              border: "none",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              type="button"
              className={`btn ${isDetailsModal ? "btn-danger" : "btn-success"}`}
              style={{ padding: "0.2rem 2rem", borderRadius: "10px" }}
              onClick={() => {
                handleExplanationModalClose();
                setTextExplanationData((prev) => {
                  if (isDetailsModal) {
                    return { ...prev };
                  } else {
                    return {};
                  }
                });
              }}
            >
              {isDetailsModal ? (
                <strong>Close</strong>
              ) : (
                <strong>
                  {isCompleted ? "Complete Test" : "Next Question"}
                </strong>
              )}
            </button>
          </div>
        </>
      </div>
    </div>
  );
};
export default ExplanationModalContent;
