export const updateStateBasedOnPath = (
  pathname,
  setAssignTest,
  setProgressTrue,
  setPracticeTrue,
  setLibraryTrue
) => {
  //based on path render the component
  const parts = pathname.split("/");
  let item = parts.pop();
  let item2 = item === "" ? parts.pop() : "";

  if (pathname.includes("assign-test")) {
    setAssignTest(true);
    setProgressTrue(false);
    setPracticeTrue(false);
    setLibraryTrue(false);
  } else if (pathname.includes("progress-report")) {
    setProgressTrue(true);
    setAssignTest(false);
    setPracticeTrue(false);
    setLibraryTrue(false);
  } else if (pathname.includes("library")) {
    setProgressTrue(false);
    setAssignTest(false);
    setPracticeTrue(false);
    setLibraryTrue(true);
  } else {
    setPracticeTrue(true);
    setProgressTrue(false);
    setAssignTest(false);
    setLibraryTrue(false);
  }
};

// handle tab
export const handleTabNavigation = (
  page,
  pathname,
  navigate,
  setPracticeTrue,
  setProgressTrue,
  setAssignTest,
  setLibraryTrue
) => {
  
  const getProgram = () => {
    if (pathname.includes("ela")) return "ela";
    if (pathname.includes("language")) return "language";
    if (pathname.includes("math")) return "math";
    if (pathname.includes("reading")) return "reading";
    if (pathname.includes("writing")) return "writing";
    return "";
  };

  const subject = getProgram();

  const navigateTo = (path) => {
    navigate(`/${subject}/${path}`);
  };

  switch (page) {
    case "practice":
      setPracticeTrue(true);
      setProgressTrue(false);
      setAssignTest(false);
      setLibraryTrue(false);
      if (pathname.includes("language")) {
        navigateTo("language/assignments");
      } else if (pathname.includes("reading")) {
        navigateTo("reading/assignments");
      } else if (pathname.includes("writing")) {
        navigateTo("writing/assignments");
      } else if (pathname.includes("spelling")) {
        navigateTo("spelling/practice");
      } else if (pathname.includes("vocabulary")) {
        navigateTo("vocabulary/practice");
      } else {
        navigateTo("assignments");
      }
      break;
    case "progress":
      setProgressTrue(true);
      setPracticeTrue(false);
      setAssignTest(false);
      setLibraryTrue(false);
      if (pathname.includes("language")) {
        navigateTo("language/progress-report");
      } else if (pathname.includes("reading")) {
        navigateTo("reading/progress-report");
      } else if (pathname.includes("writing")) {
        navigateTo("writing/progress-report");
      } else if (pathname.includes("spelling")) {
        navigateTo("spelling/progress-report");
      } else if (pathname.includes("vocabulary")) {
        navigateTo("vocabulary/progress-report");
      } else {
        navigateTo("progress-report");
      }
      break;
    case "assign":
      setAssignTest(true);
      setProgressTrue(false);
      setPracticeTrue(false);
      setLibraryTrue(false);
      if (pathname.includes("language")) {
        navigateTo("language/assign-test");
      } else if (pathname.includes("reading")) {
        navigateTo("reading/assign-test");
      } else if (pathname.includes("writing")) {
        navigateTo("writing/assign-test");
      } else if (pathname.includes("spelling")) {
        navigateTo("spelling/assign-test");
      } else if (pathname.includes("vocabulary")) {
        navigateTo("vocabulary/assign-test");
      } else {
        navigateTo("assign-test");
      }
      break;
    case "library":
      setAssignTest(false);
      setProgressTrue(false);
      setPracticeTrue(false);
      setLibraryTrue(true);
      if (pathname.includes("language")) {
        navigateTo("language/library");
      } else if (pathname.includes("reading")) {
        navigateTo("reading/library");
      } else {
        navigateTo("library");
      }
      break;
    default:
      break;
  }
};